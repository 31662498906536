import visibilitySvg from '@assets/images/visibility.svg?raw';

interface PasswordToggleTranslation {
  show: string | null;
  hide: string | null;
}

const getPasswordToggleTranslation = (): PasswordToggleTranslation => {
  const translationEl = document.querySelector('[data-login-translation]');
  if (translationEl) {
    return {
      show: translationEl.getAttribute('data-password-show-text'),
      hide: translationEl.getAttribute('data-password-hide-text'),
    };
  } else {
    return {
      show: 'Show',
      hide: 'Hide',
    };
  }
};

const insertPasswordToggler = () => {
  const translation = getPasswordToggleTranslation();

  var passwordInputs = document.querySelectorAll<HTMLInputElement>('input[type=password]');
  [].forEach.call(passwordInputs, (passwordInput: HTMLInputElement) => {
    if (!passwordInput.id) {
      return;
    }

    const passwordLabel = document.querySelector(`label[for="${passwordInput.id}"]`);
    if (!passwordLabel) {
      return;
    }

    const button = document.createElement('button');
    button.type = 'button';
    button.className = 'password-toggle';
    button.setAttribute('data-custom-style', '');
    button.insertAdjacentHTML('beforeend', visibilitySvg);

    const span = document.createElement('div');
    span.className = 'password-toggle__span';
    span.textContent = translation.show;
    button.insertAdjacentElement('beforeend', span);

    const toggle = () => {
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        span.textContent = translation.hide;
      } else {
        passwordInput.type = 'password';
        span.textContent = translation.show;
      }
    };

    const addForgotPasswordLink = () => {
      const link = document.querySelector<HTMLAnchorElement>('#forgotPassword');
      if (link) {
        link.style.order = '1000';
        passwordInput.parentElement!.insertAdjacentElement('beforeend', link);
      }
    };

    button.onclick = toggle;

    passwordLabel.insertAdjacentElement('afterend', button);
    addForgotPasswordLink();
  });
};

const replaceAcceptedText = () => {
  const translation = document.querySelector('[data-login-translation]');

  if (translation) {
    const acceptedLabel = document.querySelector('label[for="acceptTerms_accepted"]');
    const subcribeLabel = document.querySelector('label[for="subscriseMailNotification_accepted"]');

    if (acceptedLabel) {
      acceptedLabel.innerHTML = translation.getAttribute('data-accepted-term-text') || acceptedLabel.innerHTML;
    }
    if (subcribeLabel) {
      subcribeLabel.innerHTML = translation.getAttribute('data-subscrise-mail-notification-text') || subcribeLabel.innerHTML;
    }
  }
};

function setup() {
  insertPasswordToggler();
  replaceAcceptedText();
}

setup();

export {};
